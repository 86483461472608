<template>
  <div class="clue">
    <div class="all_context">
      <div class="all_context_flex">
        <div class="storeCenter_item_top_left">
          <div class="banner_box">
            <div class="activity_state two">
              <span>{{ headData.clueStage }}</span>
            </div>
            <img v-if="headData.clueImage" :src="headData.clueImage" alt="" />
          </div>
          <div class="storeCenter_item_top_left_flex">
            <div style="display: flex; align-items: center">
              <div class="clue_name">
                {{ headData.clueName }}
              </div>
              <div class="type_culb" v-if="!headData.clueCost">
                <img src="../../../../assets/image/Vector.png" alt="" />
                <span style="margin-left: 5px">免费</span>
              </div>
              <div class="type_culb" v-else>
                <span style="margin-right: 10px">押金￥{{ headData.deposit }}</span>
                <span>线索费￥{{ headData.clueCost }}</span>
              </div>
            </div>
            <div style="height: 25px" v-if="headData.clueTable">
              <el-tag
                size="mini"
                effect="plain"
                style="margin-right: 10px"
                v-for="(o, index) in newClueTableList(headData.clueTable).slice(0, 2)"
                :key="index"
                >{{ o }}</el-tag
              >
              <el-popover placement="right" width="400" trigger="hover">
                <div>
                  <el-tag
                    v-for="(i, index) in newClueTableList(headData.clueTable)"
                    :key="index"
                    size="mini"
                    effect="plain"
                    style="margin: 5px"
                  >
                    {{ i }}
                  </el-tag>
                </div>
                <el-tag
                  size="mini"
                  effect="plain"
                  slot="reference"
                  style="margin-right: 10px"
                  v-show="newClueTableList(headData.clueTable).length > 3"
                  >{{ newClueTableList(headData.clueTable).length }}+</el-tag
                >
              </el-popover>
            </div>
            <div style="display: flex" class="row">
              <div class="corp_name">{{ headData.companyFullName }}</div>
              <div style="padding: 0 10px">
                {{ headData.customerContactPerson }}
              </div>
            </div>
            <div class="bottom_row">
              <span style="margin-right: 20px">
                所在地区：{{ headData.province }}-{{ headData.city
                }}<span v-if="headData.district">-{{ headData.district }}</span>
              </span>
              <span
                >有效日期：{{ headData.clueStartTime }}至
                <span v-if="headData.estimatedCompletionTime">{{
                  headData.estimatedCompletionTime
                }}</span>
                <span v-else>---</span>
              </span>
            </div>
          </div>
        </div>
        <div class="storeCenter_item_top_right">
          <el-button type="primary" @click="goLevel">返回上一级</el-button>
        </div>
      </div>
      <div
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
      >
        <el-card shadow="never">
          <div class="context_list">
            <el-tabs v-model="query.timeType" @tab-click="changeTimeType">
              <el-tab-pane label="本周" name="1">
                <el-timeline>
                  <el-timeline-item
                    :timestamp="timeConversion(item.specialistTime || item.companyTime)"
                    placement="top"
                    v-for="(item, index) in tableData"
                    :key="index"
                  >
                    <div class="context_text">
                      <span class="context_text_blue">{{ item.userName }}</span
                      >将 “<span class="context_text_blue">{{
                        item.companyFullName || item.name
                      }}</span>
                      跟进状态 ”调整为 “
                      <span class="context_text_orange" v-if="item.specialistStatus == 1"
                        >推进中</span
                      >
                      <span class="context_text_orange" v-if="item.specialistStatus == 2"
                        >待推进</span
                      >
                      <span class="context_text_orange" v-if="item.specialistStatus == 3"
                        >取消推进</span
                      >
                      <span class="context_text_orange" v-if="item.specialistStatus == null"
                        >待推进</span
                      >
                      ”
                    </div>
                  </el-timeline-item>
                </el-timeline>
              </el-tab-pane>
              <el-tab-pane label="半个月" name="2"
                ><el-timeline>
                  <el-timeline-item
                    :timestamp="timeConversion(item.specialistTime || item.companyTime)"
                    placement="top"
                    v-for="(item, index) in tableData"
                    :key="index"
                  >
                    <div class="context_text">
                      <span class="context_text_blue">{{ item.userName }}</span
                      >将 “<span class="context_text_blue">{{
                        item.companyFullName || item.name
                      }}</span>
                      跟进状态 ”调整为 “
                      <span class="context_text_orange" v-if="item.specialistStatus == 1"
                        >推进中</span
                      >
                      <span class="context_text_orange" v-if="item.specialistStatus == 2"
                        >待推进</span
                      >
                      <span class="context_text_orange" v-if="item.specialistStatus == 3"
                        >取消推进</span
                      >
                      <span class="context_text_orange" v-if="item.specialistStatus == null"
                        >待推进</span
                      >
                      ”
                    </div>
                  </el-timeline-item>
                </el-timeline></el-tab-pane
              >
              <el-tab-pane label="一个月" name="3">
                <el-timeline>
                  <el-timeline-item
                    :timestamp="timeConversion(item.specialistTime || item.companyTime)"
                    placement="top"
                    v-for="(item, index) in tableData"
                    :key="index"
                  >
                    <div class="context_text">
                      <span class="context_text_blue">{{ item.userName }}</span
                      >将 “<span class="context_text_blue">{{
                        item.companyFullName || item.name
                      }}</span>
                      跟进状态 ”调整为 “
                      <span class="context_text_orange" v-if="item.specialistStatus == 1"
                        >推进中</span
                      >
                      <span class="context_text_orange" v-if="item.specialistStatus == 2"
                        >待推进</span
                      >
                      <span class="context_text_orange" v-if="item.specialistStatus == 3"
                        >取消推进</span
                      >
                      <span class="context_text_orange" v-if="item.specialistStatus == null"
                        >待推进</span
                      >
                      ”
                    </div>
                  </el-timeline-item>
                </el-timeline>
              </el-tab-pane>
            </el-tabs>
            <div class="search_right">
              <el-input
                placeholder="请输入关建词"
                v-model="query.keyWord"
                class="input-with-select"
                style="width: 500px"
                clearable
                @change="changeSearchangeSearch"
              >
                <el-button slot="append" @click="changeSearchangeSearch">搜索</el-button>
              </el-input>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <!-- 分页区域 -->
    <div class="new_page">
      <el-pagination
        :current-page="query.pageNum"
        :page-sizes="[4, 10, 30]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
<script>
import { listTopById } from '../../../../api/clue.js'
import { selectClueLogPage } from '../../../../api/opportunity'

const defaultOrder = Object.freeze({
  color1: '',
  color2: '#C6C6C6'
})
export default {
  name: 'expertPeople',
  computed: {
    activeId() {
      return this.$route.query.id
    }
  },
  data() {
    //这里存放数据
    return {
      //排序字段
      orderinfo: { ...defaultOrder },
      headData: {}, ///头部信息
      // 表格数据
      tableData: [],
      loading: false,
      // 分页总条数
      total: 0,
      // 表格发送数据
      query: {
        id: '',
        orders: 1,
        pageNum: 1,
        pageSize: 10,
        keyWord: '',
        orderByParam: '',
        timeType: '1'
      }
    }
  },

  created() {
    this.getHeadData()
    this.search()
  },
  //方法集合
  methods: {
    // 返回上级
    goLevel() {
      this.$router.go(-1)
    },
    // 限制字数
    truncatedText(val) {
      if (val.length > 40) {
        return val.slice(0, 40) + '...'
      }
      return val
    },
    newClueTableList(val) {
      return val.split(',')
    },
    newLabelsList(val) {
      return val.split(',')
    },
    // 时间转化
    timeConversion(val) {
      // 创建一个Date对象
      let date = new Date(val)

      // 格式化日期为YYYY-MM-DD HH:mm:ss
      let formattedDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
        '0' + date.getDate()
      ).slice(-2)} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${(
        '0' + date.getSeconds()
      ).slice(-2)}`
      return formattedDate
    },
    // tab改变
    changeTimeType(tab) {
      this.query.timeType = Number(tab.index) + 1 + ''
      this.search()
    },
    // 分页
    handleSizeChange(val) {
      this.query.pageSize = val
      this.search()
    },
    handleCurrentChange(val) {
      this.query.pageNum = val
      this.search()
    },
    // 头部数据
    async getHeadData() {
      const { data: res } = await listTopById({
        id: this.activeId
      })
      if (res.resultCode == 200) {
        this.headData = res.data
      }
    },
    async search() {
      this.query.id = this.activeId
      this.loading = true
      const { data: res } = await selectClueLogPage(this.query)
      if (res.resultCode == 200) {
        this.tableData = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    },
    // 搜索
    changeSearchangeSearch() {
      this.query.pageNum = 1
      this.search()
    }
  }
}
</script>
<style scoped lang="less">
.clue {
  overflow: hidden;
  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }
}
/deep/ a {
  color: #4e93fb;
  text-decoration: underline;
}

.all_context {
  height: calc(100vh - 150px);
  overflow: auto;
  padding: 16px 0px 0 0px;
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  .span_tag {
    font-size: 14px;
    line-height: 20px;
    color: #4e93fb;
    margin-right: 10px;
    cursor: pointer;
  }

  .all_context_flex {
    width: 100%;
    display: flex;
    background-color: #ffffff;
    padding-right: 50px;
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 20px;
    align-items: center;
    .right {
      position: absolute;
      right: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .total_num {
        margin-bottom: 10px;
        font-size: 30px;
        font-weight: 550;
        color: #ff7d18;
      }
    }
  }
  .storeCenter_item_top_left {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 120px;

    .banner_box {
      position: relative;
      width: 175.55px;
      height: 115.73px;
      padding-right: 10px;
      img {
        width: 170.55px;
        height: 117.73px;
        border-radius: 4px;
        margin-right: 18px;
      }

      .activity_state {
        position: absolute;
        width: 93px;
        height: 34px;
        background: #ff7d18;
        color: #fff;
        text-align: center;
        line-height: 34px;
        border-radius: 10px 10px 10px 0px;
        top: 0px;
        left: 0px;
        transform: translate(-10px, -10px);
      }
      .one {
        background: #ff7d18;
      }
      .two {
        background: #599afb;
      }
      .three {
        background: #999999;
      }
      .four {
        background: #999999;
      }
    }
    .context_banner_box {
      position: relative;
      width: 97px;
      height: 97px;
      padding-right: 10px;
      img {
        width: 97px;
        height: 97px;
        border-radius: 4px;
        margin-right: 18px;
      }
    }

    .storeCenter_item_top_left_flex {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .clue_num {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #6e6e6e;
        font-size: 14px;
        margin-right: 10px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }
      .clue_name {
        font-size: 18px;
        font-weight: bolder;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        min-width: 40px;
        max-width: 300px;
        margin-right: 10px;
        -webkit-box-orient: vertical;
      }

      .mark_box {
        display: flex;
        img {
          width: 14px;
          // height: 14px;
          margin-right: 6px;
          color: #6e6e6e;
        }
        & > div {
          margin-left: 10px;
        }
      }
      .img_commer {
        display: flex;
        border: 1px solid #146aff;
        border-radius: 39px;
        .name {
          font-size: 12px;
          line-height: 20px;
          color: #146aff;
        }
        .color_img {
          background: #146aff;
          height: 20px;
          width: 25px;
          border-radius: 20px;
          position: relative;
          left: -10px;
          img {
            width: 13px;
            height: 13px;
            position: relative;
            left: 5px;
            top: 2px;
          }
        }
      }
      .img_commers {
        display: flex;
        border: 1px solid #ff9c00;
        border-radius: 39px;
        margin: 0px 10px;
        .name {
          font-size: 12px;
          line-height: 20px;
          color: #ff9c00;
        }
        .color_img {
          background: #ff9c00;
          height: 20px;
          width: 25px;
          border-radius: 20px;
          position: relative;
          left: -10px;
          img {
            width: 13px;
            height: 13px;
            position: relative;
            left: 5px;
            top: 2px;
          }
        }
      }

      h4 {
        color: #1f2d3d;
        font-size: 26px;
        font-weight: 500;
        margin: 0;
      }

      .storeCenter_item_top_left_flex_tag {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-content: center;

        margin-bottom: 1px;

        span {
          box-sizing: border-box;
          display: block;
          font-size: 14px;
          font-weight: 400;
          border-radius: 4px;
          cursor: pointer;
        }

        .office {
          border: 1px solid #559eff;
          color: #4e93fb;
          padding: 1px 6px;
          margin-right: 10px;
          cursor: pointer;
        }

        .support {
          border: 1px solid #fd523f;
          color: #fd523f;
          margin-left: 12px;
          cursor: pointer;
        }
      }

      .address {
        font-size: 16px;
      }
    }
  }
  .seach_box {
    display: flex;
    margin: 15px 0;
    align-items: flex-end;
    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }
}
.type_culb {
  box-sizing: border-box;
  padding: 2px 6px;
  color: rgb(145, 144, 144);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.correlation {
  /deep/.el-dialog {
    border-radius: 4px;
    position: relative;
  }
  /deep/.el-dialog__body {
    padding: 28px 20px 16px 20px;
  }
  .correlationBox {
    .head {
      position: absolute;
      left: 0;
      top: 12px;
      // transform: translate(-20px, -50px);
      .title {
        border-left: 4px solid #448aff;
        padding-left: 15px;
        font-size: 16px;
        color: #333333;
        font-weight: 500;
      }
    }
    .context {
      .input {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .label {
          position: relative;
          font-size: 14px;
          color: #333333;
          span {
            position: absolute;
            right: -9px;
            top: -2px;
            color: #f05252;
          }
        }
        /deep/.el-input {
          width: 322px;
        }
      }
      .button {
        width: 100%;
        display: flex;
        justify-content: center;
        .text {
          width: 64px;
          height: 36px;
          cursor: pointer;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 14px;
          background-color: #448aff;
          margin-top: 31px;
        }
      }
    }
  }
}
.context_text {
  height: 50px;
}
.context_text_blue {
  color: #448aff;
}
.context_text_orange {
  color: orange;
}
.context_list {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
::v-deep .el-tabs {
  width: 60%;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 0;
}
::v-deep .el-tabs__item {
  font-size: 16px;
}
::v-deep .el-card__body {
  min-height: 500px;
}
::v-deep .el-timeline-item__timestamp {
  font-size: 18px;
  font-weight: 600;
  color: #1f2d3d;
}
::v-deep .el-timeline {
  margin: 0 -40px;
}
.search_right {
  width: 500px;
  ::v-deep .el-input-group__append {
    border-right: 0;
  }
  ::v-deep .el-button {
    border: #448aff 1px solid;
    color: #fff;
    background-color: #448aff;
  }
}
</style>
